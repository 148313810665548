function getImageWidth(src, cb) {
    var img = document.createElement("img");
    img.onload = function () {
        var imgWidth = this.width;
        var imgHeight = this.height;
        $(img).remove();
        cb(imgWidth, imgHeight);
    };
    $(img).css({left: "-99999px", position: "absolute"}).appendTo("body");
    img.src = src;
}


function UiGallery(elm, options) {
    options = $.extend({
        scrollTimeout: 700,
        animateNav: true,
        selectedIndex: 0,
        slideTimeout: 4000,
        height: 0.8,
        scale: 1.2,
        zoomEnabled: true,
        dialogFullscreen: false,
        dialogWidth: 500,
        dialogHeight: 600
    }, options);
    var $elm = $(elm),
        $body = $elm.find(".ui-gallery-body"),
        $prev = $elm.find(".ui-gallery-prev"),
        $next = $elm.find(".ui-gallery-next"),
        $navigation = $elm.find(".ui-gallery-navigation"),
        $view = $elm.find(".ui-gallery-view"),
        $desc = $elm.find(".ui-gallery-description"),
        $title = $elm.find(".ui-gallery-title"),
        isVideo = false,
        scale = 1,
        fixedHeight = false,
        fullScreen = false,
        fit = true,
        width, height, imgWidth, imgHeight, pageSliding = false, firstLoad = false;
    // return;
    $body.uiLoader();
    $navigation.find(".ui-gallery-thumb").each(function (i) {
        $(this).attr("data-ind", i);
    });



    if (options.insideDialog) {
        fixedHeight = true;
        height = options.dialogHeight;
    }

    function updateMetrics(src, params) {

        width = $body.width();


        function updateSize() {
            if (isVideo) {
                imgWidth = $body.width();
                imgHeight = imgWidth * 0.5625;
            }
            if (!fixedHeight) {
                height = $body.width() * options.height;
                if (!fit) {
                    height = width * imgHeight / imgWidth;
                }
            }

            $body.height(height);
            params = $.extend({centerX: 0.5, centerY: 0.5, move: true}, params);
            var viewWidth, viewHeight;
            if (width / height > imgWidth / imgHeight) {
                viewHeight = height;
                viewWidth = imgWidth / imgHeight * viewHeight;
            } else {
                viewWidth = width;
                viewHeight = viewWidth * imgHeight / imgWidth;

            }
            if (viewWidth > imgWidth) {
                viewWidth = imgWidth;
                viewHeight = imgHeight;
            }

            var css = {width: viewWidth * scale + "px", height: viewHeight * scale + "px"};
            // console.log("viewWidth", $view.width());
            if (firstLoad) {
                $view.css(css);
                firstLoad = false;
                TweenLite.to($view.find("img"), 0.3, {ease: Power2.easeOut, css: {opacity:1}});
                moveView(width / 2 - (viewWidth * scale * params.centerX),
                    height / 2 - (viewHeight * scale * params.centerY), viewWidth * scale, viewHeight * scale, false);
            } else {
                TweenLite.to($view, 0.3, {ease: Power2.easeOut, css: css});
                if (params.move) {
                    moveView(width / 2 - (viewWidth * scale * params.centerX),
                        height / 2 - (viewHeight * scale * params.centerY), viewWidth * scale, viewHeight * scale, !pageSliding);
                }
            }



        }

        if (!isVideo && src) {
            $body.trigger("loader.start");
            var img = $('<img src="' + src + '">').css({
                visibility: "hidden", left: "-9999px",
                position: "absolute"
            });

            var load = function () {
                //console.log("load", src);
                imgWidth = this.width;
                imgHeight = this.height;
                firstLoad = true;
                $(this).css({visibility: "", opacity:0, left: "", position: ""}).appendTo($view.empty());
                updateSize.call(this);
                $body.trigger("loader.stop");
                img.off("load", load);
            };
            img.on("load", load).appendTo("body");
//            if (img.get(0).width) {
//                img.trigger("load");
//            }
        } else {
            updateSize.call($view.children().get(0));
        }
        if (isVideo && !src) {
            src = $view.find("iframe").attr("src");
        }
        if (isVideo && src) {
            $view.empty().append('<iframe width="' + imgWidth + '" height="' + imgHeight + '" src="' + src + '" frameborder="0" allowfullscreen></iframe>');
        }
    }

    setImage(null);


    function moveView(x, y, w1, h1, animate) {
        var viewWidth = w1 || $view.width(),
            viewHeight = h1 || $view.height();
        if (viewWidth > width) {
            if (x > 0) {
                x = 0;
            } else if (x + viewWidth < width) {
                x = width - viewWidth;
            }
        } else {
            if (x < 0) {
                x = 0;
            } else if (x + viewWidth > width) {
                x = width - viewWidth;
            }
        }
        if (viewHeight > height) {
            if (y > 0) {
                y = 0;
            } else if (y + viewHeight < height) {
                y = height - viewHeight;
            }
        } else {
            if (y < 0) {
                y = 0;
            } else if (y + viewHeight > height) {
                y = height - viewHeight;
            }
        }
        if (animate) {
            TweenLite.to($view, 0.3, {
                ease: Power2.easeOut, css: {left: x + "px", top: y + "px"}, onComplete: function () {
                }
            });
        } else {
            $view.css({left: x + "px", top: y + "px"});
        }
    }

    function setImage(i) {
        var size = $navigation.find(".ui-gallery-thumb").size();
        $navigation.find(".selected").removeClass("selected");
        if (i <= 0) {
            i = 0;
            $prev.hide();
        } else {
            $prev.show();
        }
        if (i >= size - 1) {
            i = size - 1;
            $next.hide();
        } else {
            $next.show();
        }
        scale = 1;
        var $box = $navigation.find(".ui-gallery-thumb").filter(function () {
            return $(this).data("ind") == i;
        });

        isVideo = $box.hasClass("ui-gallery-videoThumb");
        $view.toggleClass("ui-gallery-video", isVideo);
        $box.closest(".ui-filmstrip-box").addClass("selected");
        updateMetrics($box.data("img"));

        $box.data("description") ? $desc.html(decodeURI($box.data("description"))) : $desc.html("");
        $box.data("title") ? $title.html($box.data("title")) : $title.html("");
    }

//    $view.onMouse({
//        "mouse.start": function (e, pos) {
//            if (scale != 1) {
//                pos.detectDrag(1, 1);
//            } else {
//                pos.detectHorizontalDrag(10, 10);
//            }
//            pos.data.x = $(this).position().left;
//            pos.data.y = $(this).position().top;
//        },
//        "drag.start": function () {
//
//        },
//        "drag.move": function (e, pos) {
//            if (scale != 1 && pos.isDrag()) {
//                var diff = pos.diff(),
//                    x = diff.x + pos.data.x,
//                    y = diff.y + pos.data.y;
//                moveView(x, y);
//            }
//
//        },
//        "mouse.stop": function (e, pos) {
//            if (!pos.isDrag() && !pos.hasMoved()) {
//                var start = pos.positionStart();
//                var center;
//                var toScale = $body.width() * options.scale / $view.width();
//                if (toScale * $view.width() < imgWidth) {
//                    toScale = imgWidth / $view.width();
//                }
//                scale = scale == 1 ? toScale : 1;
//                if (scale != 1) {
//                    center = {centerX: (start.x - $(this).offset().left) / $view.width(),
//                        centerY: (start.y - $(this).offset().top) / $view.height()};
//                }
//                $body.toggleClass("zoomed", scale != 1);
//                updateMetrics(null, center);
//            }
//        }
//    });
    var viewTouch = {};
    Hammer($view.get(0), {gesture: true, preventDefault: false}).on("tap", function (e) {
        var start = e.gesture.center;
        e.gesture.preventDefault();
        var center;
        var toScale = imgWidth / $view.width();

//        if (toScale < 1) {
//            toScale = 1.1;
//        }
        scale = scale == 1 ? toScale : 1;
        if (!options.zoomEnabled) {
            scale = 1;
        }
        if (scale == 1) {
            center = {
                centerX: (viewTouch.pageX - $(this).offset().left) / $view.width(),
                centerY: (viewTouch.pageY - $(this).offset().top) / $view.height()
            };
        }
        center = {
            centerX: 0.5, centerY: 0.5
        };
        $body.toggleClass("zoomed", scale != 1);
        updateMetrics(null, center);
    }).on("touch", function (e) {
        if (scale != 1) {
            e.gesture.preventDefault();
        }
        viewTouch = {scale: scale, pageX: $(this).position().left, pageY: $(this).position().top};
    }).on("swiperight", function (e) {
        $prev.trigger("click");
        e.gesture.preventDefault();
    }).on("swipeleft", function (e) {
        $next.trigger("click");
        e.gesture.preventDefault();
    }).on("pinch", function (e) {
        e.gesture.preventDefault();
        var start = e.gesture.center;
        var center = {};
        scale = viewTouch.scale * e.gesture.scale;
        if (scale < 1) {
            scale = 1;
        } else if (scale > 3) {
            scale = 3;
        }

        $body.toggleClass("zoomed", scale != 1);
        center.move = false;
        updateMetrics(null, center);
        var w1 = $view.width();
        var h1 = $view.height();
        var s1 = scale / viewTouch.scale;

        moveView(viewTouch.x + e.gesture.deltaX - (w1 - w1 / s1) / 2,
            viewTouch.y + e.gesture.deltaY - (h1 - h1 / s1) / 2, $view.width(), $view.height(), false);
        pageSliding = false;
    }).on("drag", function (e) {
        if (scale != 1) {
            e.gesture.preventDefault();
            moveView(viewTouch.pageX + e.gesture.deltaX, viewTouch.pageY + e.gesture.deltaY);
        }
    });

    $prev.click(function () {
        $navigation.find(".ui-filmstrip-box.selected").prev().find(".ui-gallery-thumb").click();
    });
    $next.click(function () {
        $navigation.find(".ui-filmstrip-box.selected").next().find(".ui-gallery-thumb").click();
    });


    var $elmAfter, $overlay;
    var realFullScreen = !options.dialogFullscreen;
    var fullScreenButton = $elm.find(".ui-gallery-fullScreen").click(function () {
        fullScreen = !fullScreen;
        firstLoad = true;
        $(this).toggleClass("selected", fullScreen);
        $elm.toggleClass("fullScreen", fullScreen);
        if (fullScreen) {
            $elmAfter = $("<div>").insertAfter($elm);
            fixedHeight = true;
            if (realFullScreen) {
                height = $(window).height() - $body.outerHeight(true) + $body.height();
                var width = $(window).width();
                $elm.css({
                    left: 0, top: $(window).scrollTop() + "px", width: "100%",
                    height: $(window).height() + "px"
                });
                //console.log("windowHeight", $(window).height());
                if (width > 1200) {
                    $elm.css({
                        left: (width - 1200) / 2, top: ($(window).scrollTop() + 20) + "px", width: 1200 + "px",
                        height: ($(window).height() - 40) + "px"
                    });
                }
                $overlay = $('<div class="ui-dialog-overlay">').appendTo("body");
                $("body").css({overflow: "hidden"}).append($elm);
                $overlay.css({width: $(window).width() + "px", height: $(document).height() + "px"});
                $overlay.on({
                    click: function (e) {
                        exitFullScreen();
                        updateMetrics();
                    }
                });

            } else {
                height = options.dialogHeight;
                width = options.dialogWidth;
                if ($(window).width() < width) {
                    width = $(window).width();
                }
                if ($(window).height() < height) {
                    height = $(window).height();
                }
//                $elm.uiOverlayMenu({
//                    target: $elm,
//                    detach: true,
//                    x: ($(window).width() - width) / 2 + "px",
//                    y: ($(window).height() - height) / 2 + $(window).scrollTop() + "px"
//                });
                $elm.uiDialog({width: width, height: height + $navigation.outerHeight(true)});
                $elm.css({
                    left: 0, top: 0, width: width + "px",
                    height: height + $navigation.outerHeight(true) + "px"
                });
                $("body").css({overflow: "hidden"});
            }
        } else {
            if (realFullScreen) {
                exitFullScreen();
            } else {
                $elm.trigger("dialog.close");
            }
        }
        resize();
    });
    $elm.on("dialog.close", function () {
        exitFullScreen();
        updateMetrics();
    });

    function exitFullScreen() {
        $elmAfter.after($elm);
        $elmAfter.remove();
        $elm.removeClass("fullScreen");
        fullScreenButton.removeClass("selected");
        $overlay.remove();
        fixedHeight = false;
        fullScreen = false;
        $elm.css({left: "", top: "", width: "", height: ""});
        $("body").css({overflow: ""});
        $elm.trigger("gallery.exitFullScreen");
    }

    $elm.on({
        "gallery.fit": function () {
            fit = !fit;
            $(this).toggleClass("selected", !fit);
            scale = 1;
            updateMetrics();
        }
    });

    $navigation.on({
        click: function () {
            setImage($(this).data("ind"));
        }
    }, ".ui-gallery-thumb");

    $navigation.find(".ui-gallery-thumb").each(function (i) {
        var thumb = this;
        Hammer(thumb).on("tap", function (e) {
            setImage($(thumb).data("ind"));
        });
    });

    var resize = function () {
        if (fullScreen && realFullScreen) {
            height = $(window).height() - $body.outerHeight(true) + $body.height();
            $elm.css({top: $(window).scrollTop() + "px", height: $(window).height() + "px"});
        }
        if ($overlay) {
            $overlay.css({width: $(window).width() + "px", height: $(document).height() + "px"});
        }
        updateMetrics();
    };

    var scroll = function () {
        if (fullScreen && realFullScreen) {
            $elm.css({top: $(window).scrollTop() + "px"});
        }
    };

    function escExit(e) {
        if (e.which == 27 && fullScreen) {
            exitFullScreen();
            updateMetrics();
        }
    }

    $(window).on({
        resize: resize,
        scroll: scroll,
        keyup: escExit
    });

    $elm.on({
        updateUi: function () {
            updateMetrics();
        },
        destroy: function () {
            $(window).off({
                resize: resize,
                scroll: scroll,
                escExit: escExit
            });
        }
    }).addClass("destroy");

    var ui = {
        setOptions: function (opt) {
            options = $.extend(options, opt);
        }
    };
    $elm.data("ui", ui)
}


$.fn.uiGallery = function (options) {
    options = $.extend({}, options);
    return this.each(function () {
        new UiGallery(this, options);
    });
};

